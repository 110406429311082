const localStorageKeys = {
  // Loading
  loaderHasBeenSeen: 'loader-has-been-seen',
  // Auth context
  token: 'token',
  tokenRefreshedAt: 'token-refreshed-at',
  userId: 'user-id',
  redirect: 'redirect',
  // Box context
  currentBox: 'current-box',
  boxStatus: 'box-status',
  // Shortcuts
  disableShortcuts: 'disable-shortcuts',
  // Audio context
  backgroundMusicEnabled: 'background-music-enabled',
  // Carousel
  carouselIndex: 'carousel-index',
  i18nextLanguage: 'i18next-language',

  // App 2 specific
  rescueChoice: 'rescue-choice',
  timer: 'timer',
  forestProgress: 'forest-progress',
  app2: {
    machine: 'tarot-machine',
    currentBox: 'tarot-box-current',
    boxStatus: 'tarot-box-status',
    hasSeenSmallScreenExperienceWarningModal: 'has-seen-small-screen-experience-warning-modal',
  },

  // beaurecueil
  beaurecueil: {
    machine: 'beaurecueil-machine',
    endInterrogatory: 'beaurecueil-end-interrogatory',
    hasSeenSmallScreenExperienceWarningModal: 'has-seen-small-screen-experience-warning-modal',
  },

  // ciao-bella
  ciaobella: {
    machine: 'ciao-bella-machine',
    hasSeenSmallScreenExperienceWarningModal: 'has-seen-small-screen-experience-warning-modal',
    facialCompositeFeatures: 'ciao-bella-facial-composite-features',
  },

  // woodlock
  woodlock: {
    machine: 'woodlock-machine',
    currentBox: 'woodlock-box-current',
    boxStatus: 'woodlock-box-status',
    voicemailPin: 'woodlock-voicemail-pin',
    box1Suspects: 'woodlock-box-1-suspects',
    box2Suspects: 'woodlock-box-2-suspects',
    hasSeenSmallScreenExperienceWarningModal: 'has-seen-small-screen-experience-warning-modal',
  },

  // illusion
  illusion: {
    machine: 'illusion-machine',
    currentBox: 'illusion-box-current',
    boxStatus: 'illusion-box-status',
    cipherKey: 'illusion-cipher-key',
    hasSeenSmallScreenExperienceWarningModal: 'has-seen-small-screen-experience-warning-modal',
  },
} as const;

export { localStorageKeys };
